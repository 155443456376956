@mixin search-info_private {
  .search-info {
    @include create-main-wrapper;
    background-color: $mi-bgcolor;
    &_wrapper {
      @include clearfix;
    }
    &__data {
      padding-top: $base-padding/5;
      padding-bottom: $base-padding/5;
      float: left;
      color: $mi-heading-color;
      font-size: 1rem;
      display: block;
    }
    &__modify {
      padding-top: 1px;
      padding-bottom: 1px;
      float: right;
      &_button {
        @include button-size($base-padding/6, $base-padding/2, 1rem, inherit, 0);
        @include make-button($mi-modify-btn-text-color, $mi-modify-btn-bgcolor, $mi-modify-btn-text-color, darken($mi-modify-btn-bgcolor, 10));
      }
    }
  }
  @content;
}

@mixin  search-info { 
	@include search-info_private; 
}
.sightseeing-contact__header,.sightseeing-desc__header,.sightseeing-amenity__header{
	min-height:30px;
	background-color:$sightseeing-contact-header-bg-color;
	color:$sightseeing-contact-header-text-color;
	text-transform: uppercase;
	padding: 5px;
}

.sightseeing-contact__content{
	ul{
		padding-top:10px;
		>li {
			padding-top:3px;
			border-bottom: 2px solid $sightseeing-contact-content-bottom-border-color;
			&:last-child{	
				border-bottom: none;
			}
		}
	}
}

.sightseeing-contact__content,.sightseeing-desc__content,.sightseeing-amenity__content{
	padding-left: 12px;
	padding-bottom: 5px;
	&_sightseeing-name{
		color: $sightseeing-contact-content-title-text-color;
		font-size: 12px;
	}
	&_title{
		color: $sightseeing-contact-content-title-text-color;
		font-size: 12px;
		text-transform: uppercase;
	}
	&_info{
		color:$sightseeing-contact-content-info-text-color;
		padding-bottom: 2px;
		
		.sightseeing-contact{
			&__details{
				font-weight: bold;
				width:100%;
				padding-left: 0;
				font-size: 0.8rem;
			}
			
			&__icon{
				font-size: 0.5rem;
			}	
		}
	}
	&_city{
		color:$sightseeing-contact-content-info-text-color;
	}
}

.sightseeing-desc__content{
	padding-top:10px;
}

.sightseeing-amenity__content{
	@include clearfix;
	&_amenity{
		min-width: 25%;
		float: left;
	}
}

.amenity__logo{
	padding-left: 15px;
	padding-right: 5px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.amenity__info{
	padding-right: 5px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.sightseeing-highlights-loader{
  	text-align: center;
}

.sightseeing-highlights {
  height: 600px;
  width: 100%;
  content: '';
  display: table;
  .highlights {
    &__sidebar {
      float: left;
      width: 25%;
      height: 100%;
      background-color: $body-bg;
    }
    &__sightseeing-details {
      float: right;
      width: 75%;
      height: 100%;
      margin-top: 10px;
    }
}
 
.sidebar {
    &__back-button {
      margin: 20px;
      background-color: white;
      border: 5px solid $brand-color-2;
      padding: 25px;
      button {
        cursor: pointer;
        padding-top: $base-padding/6;
        padding-bottom: $base-padding/6;
        border: none;
        line-height: inherit;
        border-radius: 0;
        background-image: none;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        font-size: 10px;
        background-color: $brand-color-1;
        color: $light;
        display: block;
        &:hover {
          background-color: darken($brand-color-1, 20);
        }
      }
    }
    &__tabs {
      width: 100%;
      height: 80%;
      padding-bottom: 10px;
      >li {
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
        position: relative;
        >a {
          color: $brand-color-2;
          padding-top: 15px;
          padding-bottom: 15px;
          display: block;
          text-decoration: none;
          cursor: pointer;
        }
        &:hover {
          background-color: $brand-color-2;
          a {
            color: $light;
          }
        }
        &:after {
          content: "";
          width: 80%;
          margin: 0 auto;
          height: 1px;
          background-color: white;
          position: absolute;
          top: 100%;
        }
      }
      .active {
        background-color: $light;
        a {
          color: $brand-color-1;
        }
      }
    }
}
  
.sightseeing-details {
    &__sightseeing-info {
      @include clearfix;
      width: 100%;
    }
    &__content {
      width: 100%;
      height: 80%;
      padding-left: 15px;
       .modal__content_sightseeing-desc, .modal__content_sightseeing-selection{
        	margin-top:1rem;
      }
    }
}

.sightseeing-info {
    &__sightseeing-image {
      float: left;
      padding-left: 15px;
      width: 20%;
      .result__thumbnail {
        width: 100%;
        height: 90px;
      }
    }
    &__sightseeing-description {
      float: left;
      width: 80%;
    }
    &__book {
	    padding-left: 1rem;
		padding-right: 1rem;
		>hr{
			border: 0.1rem solid $gray;
		}
      &_button {
        @include button-size($base-padding/6, 0, $font-size-h6, inherit, 0);
        @include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state,20));
        width: 100%;
        font-weight: 700;
        text-transform: uppercase;
        float: right;
      	width: 20%;
      }
      .price{
		&__label{
			font-size: $font-size-h3;
	        font-family: $font-family-number;
		    color: $brand-color-2;
		}&__amount {
			font-size: $font-size-h3;
	        font-family: $font-family-number;
			color: $brand-color-1;
	    }
      }
    }
}

.description__sightseeing {
    padding-left: 10px;
    width: 100%;
    &_name {
      color: $brand-color-2;
      font-weight: 400;
      text-align: left;
      font-size: 18px;
    }
}
  
.sightseeing-slideshow__header {
    min-height: 30px;
    background-color: $sightseeing-contact-header-bg-color;
    color: $sightseeing-contact-header-text-color;
    text-transform: uppercase;
    padding-left: 5px;
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
}

@mixin travel-card_private{
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  background-color: white;
  //height:200px;
  //width:230px;
  padding: 0.9375rem;
  margin-bottom: 0.9375rem;

&__header{
  	padding-bottom: 0.4375rem;
    border-bottom: 1px solid lighten($brand-color-5, 20);
    color:$brand-color-2;
  	font-weight: 600;
  	font-size:$font-size-h5;
  	text-transform:uppercase;
}

&__content{

  }


}

@mixin  travel-card{ 
	@include travel-card_private; 
}
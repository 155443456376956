@mixin create-pax-info_private {
  @include create-main-wrapper;
  @include create-row-card;
  @include create-row-card-header;
  @include create-card-content;
  &_card {
    box-shadow: $row-shadow-color;
    &_header {
      text-transform: uppercase;
      color: $brand-color-2;
    } 
    &_info{
     color: $brand-color-1;
     margin-top: 0.2rem;
     margin-bottom: 0.2rem;
     padding-left: 0.2rem
  }
  }
  
  &_row {
    @include clearfix;
  }
  &_header {
    ul > li {
      float: left;
      width: 20%;
      text-align: center;
      font-size: $font-size-h6;
      &:nth-child(1),
      &:nth-child(2) {
        width: 10%;
        text-align: center;
      }
      &:last-child {
        width: 20%;
        text-align: center;
      }
    }
  }
  &_footer {
    @include clearfix;
    border-top: 1px solid $brand-color-2;
    padding-top: $base-padding/3;
    padding-bottom: $base-padding/3;
  }
  .footer_text {
    margin-bottom: 0;
  }
  &_room {
    margin-bottom: $base-margin/2;
    border-top: 1px solid $brand-color-2;

    &_room:nth-last-child(2) {
      // border-bottom: 1px solid $brand-color-2;
    }
    &_text {
      text-transform: uppercase;
      color: $brand-color-2;
      padding-top: $base-padding/6;
      padding-bottom: $base-padding/6;
    }
    .pax-info {
      padding-bottom: 0;
    }
  }
  &_button {
    @include button-size(0.3125rem, 0 , $font-size-h4 , inherit, 0);
	@include make-button($light, $brand-color-1, $light, darken($brand-color-1, 20)); 
    border: none;
    width: 25%;
    font-weight: 700;
    float: right;
  }
  &_save-traveler{
	  @include clearfix;
	  width:100%;
	  &_button{
	  	float:right;
	  	@include make-save-traveler-button(25%);
	  	margin-bottom : 0.625rem;
	  }
  }
}

@mixin  create-pax-info { 
	@include create-pax-info_private; 
}
.sightseeing__pax-info {
  @include create-pax-info;
  padding-left: 0.9375rem;
  &_save-traveler{
	  	margin-bottom:0.9375rem;
   }
}
.view__cart {
  float: right;
  padding: $base-padding/6;
}
.sightseeing__pax-info_main .content {
  padding-left: 0.9375rem;
}
